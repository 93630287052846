import cimg1 from "../images/popular/img-1.jpg";
import cimg2 from "../images/popular/img-2.jpg";
import cimg3 from "../images/popular/img-3.jpg";
import cimg4 from "../images/popular/img-4.jpg";
import cimg5 from "../images/popular/img-5.jpg";
import cimg6 from "../images/popular/img-6.jpg";
import cimg7 from "../images/popular/img-7.jpg";

const Courses = [
  {
    Id: "1",
    cImg: cimg1,
    ratting: "4.5",
    title: "Exploring the World of Arts",
    slug: "Exploring the World of Arts",
    description: "Where melodies meet masterpieces.",
  },
  {
    Id: "2",
    cImg: cimg2,
    ratting: "5.0",
    title: "Crescendo of Talents",
    slug: "Crescendo of Talents",
    description: "Journeying Through Melodies and Instruments.",
  },
  {
    Id: "3",
    cImg: cimg3,
    ratting: "4.8",
    title: "A Taekwondo Journey",
    slug: "A Taekwondo Journey",
    description: "Taekwondo: where mind, body, and spirit unite.",
  },
  {
    Id: "4",
    cImg: cimg4,
    ratting: "4.9",
    title: "The Kickboxing Journey",
    slug: "The Kickboxing Journey",
    description: "Empowering with Kickboxing Wisdom",
  },
  {
    Id: "5",
    cImg: cimg5,
    ratting: "4.2",
    title: "Nutrition and Health",
    slug: "Nutrition and Health",
    description: "Essential Between Nutrition and Health",
  },
  {
    Id: "6",
    cImg: cimg6,
    ratting: "4.3",
    title: "Fluid Motion Swim & Skating",
    slug: "Fluid Motion Swim & Skating",
    description: "A Symphony of Swimming and Skating",
  },
  {
   Id: "7",
   cImg: cimg7,
   ratting: "4.3",
   title: "Dance Hub, Your Ultimate Guide to the World of Dance",
   slug: "Dance Hub, Your Ultimate Guide to the World of Dance",
   description: "Dance is the poetry of the foot. Dance is the hidden language of the soul; a dancer speaks volumes without saying a word.",
 },
];

export default Courses;

import cimg1 from '../images/category/img-1.jpg'
import cimg2 from '../images/category/img-2.jpg'
import cimg3 from '../images/category/img-3.jpg'



const CoursesCategory = [
   {
      Id: '1',
      cImg: cimg1,
      thumb: 'flaticon-user-experience',
      title: 'Music & Art',
      slug: 'Music & Art',
      description: 'Experience the symphony of colors and sounds.',
   },
   {
      Id: '2',
      cImg: cimg2,
      thumb: 'flaticon-megaphone',
      title: 'Fitness & Health',
      slug: 'Fitness & Health',
      description: 'Your health is your wealth; invest your time in fitness.'
   },
   {
      Id: '3',
      cImg: cimg3,
      thumb: 'flaticon-code',
      title: 'Sports',
      slug: 'Sports',
      description: 'Play with heart, win with pride.'
   },
]

export default CoursesCategory;
// images
// import blogImg1 from "../images/blog/img-1.jpg";
// import blogImg2 from "../images/blog/img-2.jpg";
// import blogImg3 from "../images/blog/img-3.jpg";

import blogSingleImg1 from "../images/blog/blog-single/img-1.jpg";
import blogSingleImg2 from "../images/blog/blog-single/img-2.jpg";
import blogSingleImg3 from "../images/blog/blog-single/img-3.jpg";



const blogs = [
    {
        id: '1',
        title: 'Resonance of Perfection: A Maestros Handbook to Musical Excellence',
        slug:'Resonance of Perfection: A Maestros Handbook to Musical Excellence',
        description: 'Explore elite insights and industry expertise with Harmonious Notes. Our concise, curated content offers advanced tutorials, trend analysis, tailored for professionals and enthusiasts. Join us at the pinnacle of musical excellence. Welcome to Harmonious Notes."',
        blogSingleImg:blogSingleImg1, 
        blClass:'format-standard-image',
        animation:'1200',
    },
    {
        id: '2',
        title: 'Top-Tier Sports Insights: Your Ultimate Sports Blog',
        slug:'Top-Tier Sports Insights: Your Ultimate Sports Blog',
        description: 'For professionals and enthusiasts alike, Elite Sports Analysis provides expert commentary, game breakdowns, and exclusive insights. Stay ahead of the game with our premier sports coverage.',
        blogSingleImg:blogSingleImg2, 
        blClass:'format-standard-image',
        animation:'1400',
    },
    {
        id: '3',
        title: 'Conquering Martial Arts: Your Definitive Guide',
        slug:'Conquering Martial Arts: Your Definitive Guide',
        description: 'Refine your skills with Martial Arts Mastery. Get expert insights, training tips, and exclusive interviews with top masters.',
        blogSingleImg:blogSingleImg3,
        blClass:'format-video',
        animation:'1600',
    }
];
export default blogs;
import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        slug:'J.R Joseph',
        name:'J.R Joseph',
        title:'Music Director',
    },
    {
        Id: '2',
        tImg:timg2,
        slug:'Naren',
        name:'Naren',
        title:'MMA Trainer ',
    },
    {
        Id: '3',
        tImg:timg3,
        slug:'Vinoth Kumar',
        name:'Vinoth Kumar',
        title:'Fitness Health Nutritionist',
    },
    {
        Id: '4',
        tImg:timg4,
        slug:'Dingesh',
        name:'Dingesh',
        title:'Multi-Disciplinary Instructor',
    }
]

export default Teams;